<template>
  <v-form v-model="valid" ref="addDisplay">
    <modals-container v-on:done="clearForm"></modals-container>
    <v-layout>
      <v-flex xs12>
        <v-toolbar color="#f8f9fa" dark>
          <sales-order-input v-on:setDisplayFormFromSalesOrderDetails="updateDisplayForm"></sales-order-input>
          <v-spacer></v-spacer>
          <v-btn class="success" @click="showConfirmationModal">Save</v-btn>
        </v-toolbar>
        <v-card>
          <display-form :display="display" :mode="'add'"></display-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import DisplayForm from '@/components/forms/display-form'
import DisplayMixin from '@/mixins/display-mixin'
import SalesOrderInput from '@/components/inputs/salesorder-input'
import AddDisplayVerifyModal from '@/components/modals/AddDisplayVerify'
import toast from '@/plugins/notification'
import DisplayModel from '@/store/models/DisplayModel'
import CompanyModel from '@/store/models/CompanyModel'

export default {
  name: 'add-display',
  data: () => ({
    unique: true,
    valid: false
  }),
  methods: {
    clearForm() {
      this.$refs.addDisplay.resetValidation()
      this.resetDisplay()
    },
    isValidForm(params) {
      return params.displayserialid.length <= 30 & params.alias.length <= 100 && params.manufacturer.length <= 100
          && params.registerkey.length <= 30
    },
    showConfirmationModal() {
      if (!this.isUniqueSerialID) {
        toast.displayError('Please choose a unique Serial ID.')
      } else if (this.$refs.addDisplay.validate() && this.isValidForm(this.display)) {
        this.$modal.show(AddDisplayVerifyModal, {item: this.display}, {
          height: 'auto',
          width: '500',
          pivotX: .33,
          pivotY: .33,
          clickToClose: false
        })
      }
    },
    updateDisplayForm() {
      Object.assign(this.display, DisplayModel.getSelectedDisplay())
      let selected = CompanyModel.getCompanyProfilesList().filter(company => company.businessname.toString().toLowerCase() === this.display.alias.toString().toLowerCase())
      if (selected.length > 0) {
        this.display.companyid = selected[0].companyid
      } else {
        this.display.companyid = null
      }
    }
  },
  computed: {
    isUniqueSerialID() {
      return this.displayList.findIndex(display => display.displayserialid === this.display.displayserialid) === -1
    }
  },
  components: {
    DisplayForm,
    SalesOrderInput
  },
  mixins: [DisplayMixin]
}
</script>
