<template>
  <v-row class="ma-4">
    <v-col cols="6">
      <v-autocomplete
          v-model="display.companyid"
          :items="companyList"
          :rules="selectRules"
          :disabled="mode === 'edit'"
          item-text="businessname"
          item-value="companyid"
          label="Companies"
          required
      ></v-autocomplete>
      <v-text-field
          v-model="display.displayserialid"
          :rules="displayserialidRules"
          :disabled="mode === 'edit'"
          counter="30"
          label="Serial ID"
          required
      ></v-text-field>
      <v-text-field
          v-model="display.alias"
          :rules="displaynameRules"
          counter="100"
          label="Alias"
          required
      ></v-text-field>
      <v-select
          v-model="display.country"
          :items="countryList"
          item-text="name"
          item-value="abbreviation"
          label="Country"
          required
          @change="setSelectedCountry"
      ></v-select>
      <v-layout>
        <v-col cols="6">
          <v-select v-if="isState"
                    v-model="display.state"
                    :items="stateList"
                    item-text="state"
                    item-value="state"
                    label="State"
                    required
          ></v-select>
          <v-text-field v-else
                        v-model="display.province"
                        counter="100"
                        label="Province"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="display.timezone"
              :rules="selectRules"
              :items="timezones"
              item-text="name"
              item-value="value"
              label="Timezone"
              required
          ></v-select>
        </v-col>
      </v-layout>
      <v-text-field
          v-model="display.manufacturer"
          counter="100"
          label="Manufacturer"
      ></v-text-field>
      <v-text-field
          v-model="display.dealer"
          label="Dealer"
          readonly
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-row>
        <v-col cols="3">
          <v-text-field
              v-model="display.height"
              :rules="dimensionRules"
              label="Height"
              @keypress="restrictToInteger"
              @paste.prevent
              required
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              v-model="display.width"
              :rules="dimensionRules"
              label="Width"
              @keypress="restrictToInteger"
              @paste.prevent
              required
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              v-model="display.offsetx"
              label="Offset X"
              @keypress="restrictToInteger"
              @paste.prevent
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              v-model="display.offsety"
              label="Offset Y"
              @keypress="restrictToInteger"
              @paste.prevent
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field
              v-model="display.pitch"
              label="Pitch"
              @keypress="restrictToFloat"
              @paste.prevent
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="display.mode"
              :rules="selectRules"
              :items="modes"
              item-text="name"
              item-value="value"
              label="Mode"
              required
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="display.type"
              :rules="selectRules"
              :items="types"
              item-text="name"
              item-value="value"
              label="Type"
              required
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="display.playermode"
              :rules="selectRules"
              :items="player_mode"
              item-text="name"
              item-value="value"
              label="Mode"
              required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-select
              v-model="display.playertype"
              :rules="selectRules"
              :items="controllers"
              item-text="name"
              item-value="value"
              label="Controller"
              @change="changedPlayerType"
              required
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
              v-model="display.playersoftware"
              :rules="selectRules"
              :items="software"
              item-text="name"
              item-value="value"
              label="Software"
              required
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
              v-model="display.maxplayerversion"
              label="Version"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-select
              v-model="display.lcd"
              :rules="selectRules"
              :items="technologies"
              item-text="name"
              item-value="value"
              label="Technology"
              required
          ></v-select>
        </v-col>
        <v-col cols="5">
          <v-select
              v-model="display.lightsensor"
              :items="lightSensorList"
              :rules="selectRules"
              item-text="name"
              item-value="value"
              label="Light Sensor"
              required
              @input="selectLightSensorChangeHandler"
          >
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-select
              v-model="display.brightnesscontroltype"
              :items="brightness"
              :rules="selectRules"
              item-text="name"
              item-value="value"
              label="Brightness Control"
              required
              return-object
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
              v-model="display.registerkey"
              counter="30"
              label="Register Key"
              append-outer-icon="mdi-redo"
              @click:append-outer="getNewRegisterKey"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              v-if="display.playertype === 'ANDROID'"
              v-model="display.dvi_boards"
              label="DVI Boards"
              @keypress="restrictToInteger"
              @paste.prevent
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
              v-if="isAndroidController"
              v-model="display.logic_boards"
              label="Logic Boards"
              @keypress="restrictToInteger"
              @paste.prevent
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-checkbox v-model="hasLicense" class="mx-2" label="License?"></v-checkbox>
        <v-col cols="3" v-if="hasLicense">
          <v-text-field
              v-model="display.licenseterm"
              label="Length"
          >
          </v-text-field>
        </v-col>
        <v-col v-if="hasLicense">
          <v-text-field v-if="this.isEditMode"
                        v-model="display.licenseexpired"
                        label="Expires"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import CompanyModel from '@/store/models/CompanyModel'
import ProducerModel from '@/store/models/ProducerModel'
import UserModel from '@/store/models/UserModel'
import {generateKey, isInt} from '@/plugins/util'
import toast from '@/plugins/notification'

export default {
  name: 'display-form',
  data: () => ({
    brightness: [
      {name: 'On/Off', value: 'on-off'},
      {name: 'On/Off - Hybrid', value: 'on-off-hybrid'},
      {name: 'Half-Hourly', value: 'half-hourly'},
      {name: 'Hardware', value: 'hardware'}
    ],
    companies: [],
    controllers: [
      {name: 'Android', value: 'ANDROID'},
      {name: 'IBase', value: 'HTML5'},
      {name: 'LightSpeed', value: 'LIGHTSPEED'}
    ],
    country: 'USA',
    dealer: "",
    dealers: [
      {name: 'VANTAGE', value: '2'}
    ],
    dimensionRules: [
      v => !!v || 'Value is required',
      v => isInt(v) || 'Valid is invalid'
    ],
    displaynameRules: [
      v => !!v || 'Name is required',
      v => (v.toString() || '').length <= 100 || 'Name must be less than 100 characters'
    ],
    displayserialidRules: [
      v => !!v || 'Serial ID is required',
      v => v.length <= 30 || 'Serial ID must be less than 30 characters',
      v => !v.includes('-') || 'You cannot use a dash in this field'
    ],
    dvi_boards: 1,
    light_sensors: [
      {name: 'Software', value: 'software', type: 'HTML5'},
      {name: 'Hardware', value: 'hardware', type: 'BOTH'},
      {name: 'Hybrid-Hardware', value: 'hybrid-hardware', type: 'ANDROID'}
    ],
    list: [],
    logic_boards: 1,
    modes: [
      {name: 'Physical', value: 'Physical'},
      {name: 'Virtual', value: 'Virtual'}
    ],
    player_mode: [
      {name: 'Testing', value: 'testing'},
      {name: 'Finalized', value: 'finalized'},
      {name: 'Shipped', value: 'shipped'},
      {name: 'Actual', value: 'actual'}
    ],
    salesorderid: '',
    selectRules: [
      v => !!v || 'Selection is required'
    ],
    selected_controller: 'ANDROID',
    software: [
      {name: 'SM Infinity', value: 'SM Infinity'},
      {name: 'SM 2.0', value: 'SM 2.0'},
      {name: 'LightSpeed', value: 'LightSpeed'},
      {name: 'LightForce', value: 'LightForce'}
    ],
    state: '',
    technologies: [
      {name: 'LED', value: '0'},
      {name: 'LCD', value: '1'}
    ],
    timezones: [
      {name: 'CDT', value: 'CDT'},
      {name: 'ADT', value: 'ADT'},
      {name: 'PDT', value: 'PDT'},
      {name: 'MDT', value: 'MDT'},
      {name: 'EDT', value: 'EDT'},
      {name: 'HDT', value: 'HDT'},
      {name: 'AKDT', value: 'AKDT'}
    ],
    types: [
      {name: 'RGB', value: 'RGB'},
      {name: 'MONO - Red', value: 'MONO - Red'},
      {name: 'MONO - Amber', value: 'MONO - Amber'}
    ],
    valid: false,
    versions: ProducerModel.getPlayerVersions()
  }),
  props: {
    display: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  mounted() {
    if (CompanyModel.getCompanyProfilesList().length === 0) {
      CompanyModel.getCompanyProfiles({sessionkey: UserModel.getSessionkey()})
          .then(() => {
            this.companyList = CompanyModel.getCompanyProfilesList()
          })
          .catch(error => {
            console.error(error)
            toast.displayError('There was an error retrieving your data. Please try again later.')
          })
    } else {
      this.companyList = CompanyModel.getCompanyProfilesList()
    }
    if (this.mode === 'add') {
      this.display.displaysoftware = this.display.playersoftware = this.display.software = 'SM Infinity'
      this.display.registerkey = this.generateRegisterKey()
      if (this.versions.length > 0) {
        this.display.maxplayerversion = this.versions.filter(version => version.type === "HTML5")[0].version
      }
      this.display.dvi_boards = this.dvi_boards
      this.display.logic_boards = this.logic_boards
    }
  },
  methods: {
    changedPlayerType() {
      if (this.display.lightsensor === "software") {
        this.display.lightsensor = "hybrid-hardware"
      }
      if (this.display.playertype === "ANDROID") {
        this.display.maxplayerversion = this.versions.filter(version => version.type === this.display.playertype)[0].version
      } else {
        this.display.maxplayerversion = this.versions.filter(version => version.type !== "ANDROID")[0].version
      }

    },
    generateRegisterKey() {
      return generateKey(12)
    },
    getNewRegisterKey() {
      this.display.registerkey = this.generateRegisterKey()
    },
    isEditMode() {
      return this.mode === 'edit'
    },
    restrictToFloat(e) {
      let value = e.target.value + e.key
      if (isNaN(parseFloat(value)) || (value.match(/\./g) || []).length > 1) {
        e.preventDefault()
      }
    },
    restrictToInteger(e) {
      let value = e.target.value + e.key
      if (isNaN(parseInt(value, 10)) || e.key === 'e' || e.key === '+' || e.key === '.') {
        e.preventDefault()
      }
    },
    selectLightSensorChangeHandler(event) {
      switch (this.display.lightsensor) {
        case 'hardware':
          if (this.display.playertype === 'HTML5') {
            this.display.brightnesscontroltype = {name: 'On/Off - Hybrid', value: 'on-off-hybrid'}
          } else {
            this.display.brightnesscontroltype = {name: 'On/Off', value: 'on-off'}
          }
          break
        case 'software':
          this.display.brightnesscontroltype = {name: 'Half-Hourly', value: 'half-hourly'}
          break
        case 'hybrid-hardware':
          this.display.brightnesscontroltype = {name: 'Half-Hourly', value: 'half-hourly'}
          break
      }
    },
    setSelectedCountry() {
      this.country = this.display.country
    },
  },
  computed: {
    companyList: {
      get() {
        return this.companies
      },
      set(value) {
        this.companies = value
      }
    },
    countryList() {
      return ProducerModel.getCountries()
    },
    dealerList() {
      return ProducerModel.getDealerList()
    },
    displayList: {
      get() {
        return this.list
      },
      set(value) {
        this.list = value
      }
    },
    hasLicense: {
      get() {
        return this.display.license === 'true'
      },
      set(value) {
        this.display.license = value.toString()
      }
    },
    isAndroidController() {
      return this.display.playertype.toLowerCase() === 'android'
    },
    isState() {
      return this.country === 'USA'
    },
    lightSensorList() {
      return this.light_sensors.filter((sensor) => {
        return sensor.type === this.display.playertype || sensor.type === "BOTH"
      })
    },
    stateList() {
      return ProducerModel.getStates()
    }
  }
}
</script>
