<template>
  <v-card>
    <v-card-title class="modal-title-bar headline">
      Confirmation
      <v-spacer></v-spacer>
      <v-btn :disabled='isProcessing' dark icon @click.prevent="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="ma-3">
        <label>Are you sure you want to delete the following company?</label>
      </div>
      <div class="ma-3">
        <label><b>{{ company.businessname }}</b></label>
      </div>
    </v-card-text>
    <v-card-actions class="modal-actions-bar justify-center">
      <v-btn :disabled='isProcessing' @click="closeModal" text rounded class="button-card modal-cancel mr-4">
        No
      </v-btn>
      <v-btn :disabled='isProcessing' @click="save" text rounded class="button-card modal-confirm mr-4"
             :loading="isProcessing">
        Yes
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
        <template v-if="isComplete">
          <v-icon color="success" class="ml-2">{{ icon.valid }}</v-icon>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import CompanyModel from "@/store/models/CompanyModel"
import UserModel from "@/store/models/UserModel"
import {THUMB_UP_OUTLINE} from '@/assets/constants'
import toast from '@/plugins/notification'

export default {
  name: "DeleteCompanyConfirmModal",
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    icon: {
      valid: THUMB_UP_OUTLINE
    },
    isComplete: false,
    isProcessing: false
  }),
  methods: {
    closeModal() {
      this.$emit('close')
    },
    remove(id) {
      return new Promise((resolve, reject) => {
        CompanyModel.deleteCompany({sessionkey: UserModel.getSessionkey(), identifier: id})
            .then(() => {
              resolve()
            })
            .catch(error => {
              console.error(error)
              reject(error)
            })
      })
    },
    save() {
      this.isProcessing = true
      this.remove(this.company.companyid).then(() => {
        this.isComplete = true
        this.$emit('done')
        this.closeModal()
        toast.displayInfo("The company was deleted.")
      }).catch(error => {
        console.error(error)
        this.closeModal()
        toast.displayError("There was an error deleting this company. Please try again later")
      })
    }
  }
}
</script>
