import * as displayGetters from './display/getters'
import * as displayMutations from './display/mutations'
import * as displayActions from './display/actions'

const state = {
  list: [],
  selected: {}
}

const getters = displayGetters
const mutations = displayMutations
const actions = displayActions

export default {
  state,
  getters,
  mutations,
  actions
}
