import store from '@/store/store'

export default class UserModel {
  static login(params = {}) {
    this.setSessionkey("login")
    return new Promise((resolve, reject) =>
      store.dispatch('getfromdatabase', Object.assign(params, {route: "constructor::login"}))
           .then(result => {
             if (result.data.code !== 200){
               reject(result)
             }
             this.setSessionkey(result.data.data.sessionkey)
             resolve(result.data.data)
           })
           .catch(error => reject(error)))
  }

  static logout(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getfromdatabase', Object.assign(params, {route: "website::logout"}))
           .then(result => {
             if (result.data.code !== 200){
               reject(result)
             }
             resolve()
           })
           .catch(error => reject(error)))
  }

  static getSessionkey() {
    return store['getters']['getSessionkey']
  }

  static setSessionkey(payload) {
    return store.dispatch('setSessionkey', payload)
  }
}
