export const getCountriesFromStore = state => {
  return state.countries
}

export const getDealersFromStore = state => {
  return state.dealers
}

export const getPlayerVersionsFromStore = state => {
  return state.playerversions
}

export const getSalesOrdersFromStore = state => {
  return state.salesorders
}

export const getSpectacularPackagesFromStore = state => {
  return state.spectacularpackages
}

export const getStatesFromStore = state => {
  return state.states
}
