<template>
  <v-form ref="deleteCompany">
    <modals-container v-on:done="clearForm"></modals-container>
    <v-layout>
      <v-flex xs12>
        <v-toolbar color="#f8f9fa" dark>
          <company-select v-on:selectedChanged="selectedChanged"></company-select>
          <v-spacer></v-spacer>
          <v-btn class="error" :disabled="!isSelectionMade" @click="deleteCompany">Delete</v-btn>
        </v-toolbar>
        <v-card>
          <v-container fluid grid-list-lg class="ma-3">
            <v-layout>
              <v-flex xs2>
                <v-label>Name</v-label>
              </v-flex>
              <v-flex xs8>
                <v-text-field solo readonly v-model="company.businessname"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs2>
                <v-label>Email</v-label>
              </v-flex>
              <v-flex xs8>
                <v-text-field solo readonly v-model="company.email"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs2>
                <v-label>Address</v-label>
              </v-flex>
              <v-flex xs4>
                <v-text-field solo readonly v-model="company.address1"></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field solo readonly v-model="company.address2"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs2>
                <v-label>City</v-label>
              </v-flex>
              <v-flex xs3>
                <v-text-field solo readonly v-model="company.city"></v-text-field>
              </v-flex>
              <v-flex xs2>
                <v-label>Zip/Postal</v-label>
              </v-flex>
              <v-flex xs3>
                <v-text-field solo readonly v-model="company.zip"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs2>
                <v-label>Country</v-label>
              </v-flex>
              <v-flex xs3>
                <v-text-field solo readonly v-model="company.country"></v-text-field>
              </v-flex>
              <v-flex xs2>
                <v-label>State/Province</v-label>
              </v-flex>
              <v-flex xs3>
                <v-text-field solo readonly v-model="isStateOrProvince"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs2>
                <v-label>Telephone</v-label>
              </v-flex>
              <v-flex xs5>
                <v-text-field solo readonly v-model="company.phone"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import DeleteCompanyConfirmModal from '@/components/modals/DeleteCompanyConfirm'
import CompanyMixin from '@/mixins/company-mixin'
import CompanySelect from '@/components/selects/company-select'

export default {
  name: 'delete-company',
  data: () => ({
    selected: false
  }),
  methods: {
    clearForm() {
      this.$refs.deleteCompany.reset()
      this.selected = false
    },
    deleteCompany() {
      // what about long company names?
      this.$modal.show(DeleteCompanyConfirmModal, {company: this.company},
          {height: 'auto', width: '450', pivotX: .50, pivotY: .33, clickToClose: false})
    },
    selectedChanged(e) {
      if (typeof e !== 'undefined' && e !== null) {
        this.selected = true
        this.company = e
      } else {
        this.clearForm()
      }
    }
  },
  computed: {
    isSelectionMade() {
      return this.selected
    },
    isStateOrProvince: {
      get() {
        if (this.isSelectionMade) {
          return (this.company.country === 'USA')
              ? this.company.state
              : this.company.province
        }
        return ''
      },
      set() {
      }
    }
  },
  components: {
    CompanySelect
  },
  mixins: [CompanyMixin]
}
</script>
