export const addCompany = (state, payload) => {
  state.list.push(payload)
  state.list.sort((a, b) => {
    return (a.businessname.toLowerCase() < b.businessname.toLowerCase())
           ? -1
           : 1
  })
}

export const removeCompany = (state, payload) => {
  let index = state.list.findIndex(company => company.companyid === payload)
  if (index !== -1){
    state.list.splice(index, 1)
  }
}

export const setCompanyList = (state, payload) => state.list = payload

export const setSelectedCompany = (state, payload) => state.selected = payload

export const setSelectedCompanyByIndex = (state, payload) => {
  state.selected = state.list[payload]
}

export const updateCompany = (state, payload) => {
  let index = state.list.findIndex(company => company.companyid === payload.companyid)
  if (index !== -1){
    for (let item in payload) {
      state.list[index][item] = payload[item]
    }
  }
}
