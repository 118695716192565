import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"modal-title-bar headline"},[_vm._v(" Confirmation "),_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.isProcessing,"dark":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.closeModal.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VLayout,{attrs:{"wrap":"","justify-center":"","fill-height":"","ma-3":""}},[_c('label',[_vm._v("Are you sure you want to delete ("+_vm._s(_vm.display.displayserialid)+") "+_vm._s(_vm.display.alias)+" ?")])])],1),_c(VCardActions,{staticClass:"modal-actions-bar justify-center"},[_c(VBtn,{staticClass:"button-card modal-cancel mr-4",attrs:{"disabled":_vm.isProcessing,"text":"","rounded":""},on:{"click":_vm.closeModal}},[_vm._v(" No ")]),_c(VBtn,{staticClass:"button-card modal-confirm mr-4",attrs:{"disabled":_vm.isProcessing,"text":"","rounded":"","loading":_vm.isProcessing},on:{"click":_vm.save},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c(VIcon,{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}])},[_vm._v(" Yes ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }