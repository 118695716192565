import Vue from 'vue'
import Vuex from 'vuex'
import company from './modules/company'
import display from './modules/display'
import producer from './modules/producer'
import site from './modules/site'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    company,
    display,
    producer,
    site,
    user
  }
})
