<template>
  <v-form ref="deleteDisplay">
    <modals-container v-on:done="clearForm"></modals-container>
    <v-layout>
      <v-flex xs12>
        <v-toolbar color="#f8f9fa" dark>
          <display-select v-on:selectedChanged="selectedChanged"></display-select>
          <v-spacer></v-spacer>
          <v-btn class="error" :disabled="!isSelectionMade" @click="showConfirmationModal">Delete</v-btn>
        </v-toolbar>
        <v-card>
          <v-container fluid grid-list-lg class="ma-3">
            <v-layout>
              <v-flex xs2>
                <v-label>Name</v-label>
              </v-flex>
              <v-flex xs8>
                <v-text-field solo readonly v-model="display.alias"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs2>
                <v-label>Address</v-label>
              </v-flex>
              <v-flex xs8>
                <v-layout>
                  <v-text-field solo readonly v-model="display.address1"></v-text-field>
                </v-layout>
                <v-layout>
                  <v-text-field solo readonly v-model="display.address2"></v-text-field>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs2>
                <v-label>City</v-label>
              </v-flex>
              <v-flex xs3>
                <v-text-field solo readonly v-model="display.city"></v-text-field>
              </v-flex>
              <v-flex xs2>
                <v-label>Zip/Postal</v-label>
              </v-flex>
              <v-flex xs3>
                <v-text-field solo readonly v-model="display.zip"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs2>
                <v-label>Country</v-label>
              </v-flex>
              <v-flex xs3>
                <v-text-field solo readonly v-model="display.country"></v-text-field>
              </v-flex>
              <v-flex xs2>
                <v-label>State/Province</v-label>
              </v-flex>
              <v-flex xs3>
                <v-text-field solo readonly v-model="isStateOrProvince"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import DeleteDisplayConfirmModal from '@/components/modals/DeleteDisplayConfirm'
import DisplayMixin from '@/mixins/display-mixin'
import DisplaySelect from '@/components/selects/display-select'
import CompanySelect from '@/components/selects/company-select'

export default {
  name: 'delete-display',
  data: () => ({
    selected: false
  }),
  methods: {
    clearForm() {
      this.$refs.deleteDisplay.reset()
      this.selected = false
    },
    selectedChanged(e) {
      if (typeof e !== 'undefined' && e !== null) {
        this.selected = true
        this.display = e
      } else {
        this.clearForm()
      }
    },
    showConfirmationModal() {
      this.$modal.show(DeleteDisplayConfirmModal, {display: this.display},
          {height: 'auto', width: '400', pivotX: .50, pivotY: .33, clickToClose: false})
    }
  },
  computed: {
    isSelectionMade() {
      return this.selected
    },
    isStateOrProvince: {
      get() {
        if (this.isSelectionMade) {
          return (this.display.country === 'USA')
              ? this.display.state
              : this.display.province
        }
        return ''
      },
      set() {
      }
    }
  },
  components: {
    DisplaySelect
  },
  mixins: [DisplayMixin]
}
</script>
