import APIResource from '@/store/resources/APIResource'

export const addCompanyAPI = ({}, params) => APIResource.callAPI(params)

export const addCompanyToList = ({commit}, payload) => commit('addCompany', payload)

export const deleteCompanyAPI = ({}, params) => APIResource.callAPI(params)

export const getCompanyAPI = ({}, params) => APIResource.callAPI(params)

export const getCompanyProfilesAPI = ({}, params) => APIResource.callAPI(params)

export const getSpectacularCreditsAPI = ({}, params) => APIResource.callAPI(params)

export const removeCompanyFromList = ({commit}, payload) => commit('removeCompany', payload)

export const setCompanyList = ({commit}, payload) => commit('setCompanyList', payload)

export const setSelectedCompany = ({commit}, payload) => commit('setSelectedCompany', payload)

export const setSelectedCompanyByIndex = ({commit}, payload) => commit('setSelectedCompanyByIndex', payload)

export const updateCompany = ({commit}, payload) => commit('updateCompany', payload)

export const updateCompanyAPI = ({}, params) => APIResource.callAPI(params)

export const updateCompanyProfileAPI = ({}, params) => APIResource.callAPI(params)

export const updateSpectacularCreditsAPI = ({}, params) => APIResource.callAPI(params)
