<template>
  <v-form v-model="valid" ref="editDisplay">
    <modals-container v-on:save="updateDisplay"></modals-container>
    <v-layout>
      <v-flex xs12>
        <v-toolbar color="#f8f9fa" dark>
          <display-select v-on:selectedChanged="selectedChanged"></display-select>
          <v-spacer></v-spacer>
          <v-btn class="success" :disabled="!isSelectionMade" @click="updateDisplay">Save</v-btn>
        </v-toolbar>
        <v-card>
          <display-form :display="display" :mode="'edit'"></display-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
  import DisplayForm from '@/components/forms/display-form'
  import DisplayMixin from '@/mixins/display-mixin'
  import DisplaySelect from '@/components/selects/display-select'
  import { checkFieldsForNull, getDifferenceOfObjects } from '@/plugins/validator'
  import toast from '@/plugins/notification'

  export default {
    name: 'edit-display',
    data: () => ({
      selected: false,
      selection: {},
      unique: true,
      valid: false
    }),
    methods: {
      getChangedFormFields() {
        let changed = getDifferenceOfObjects(this.selection, this.display)
        if (Object.prototype.hasOwnProperty.call(changed, "country")){
          if (changed.country === 'USA'){
            changed.province = 'NULL'
            changed.state = this.display.state
          } else {
            changed.province = this.display.province
            changed.state = 'NULL'
          }
        }
        if (Object.prototype.hasOwnProperty.call(changed, "license") && changed.license === "false") {
          changed.licenseexpired = "NULL"
        }
        if (Object.prototype.hasOwnProperty.call(changed, "playertype")) {
          if (changed.playertype === "Android") {
            // test
          }
        }
        if (Object.prototype.hasOwnProperty.call(changed, "playersoftware")){
          changed.displaysoftware = changed.software = this.display.playersoftware
        }
        let intersection = ['height', 'width', 'offsetx', 'offsety'].filter(prop => Object.keys(changed)
                                                                                          .includes(prop))
        if (intersection.length > 0){
          intersection.map(item => changed[item] = parseInt(changed[item]))
        }
        return checkFieldsForNull(changed)
      },
      resetSelectedDisplayByIndex() {
        this.selected = false
        this.resetDisplay()
        this.$refs.editDisplay.resetValidation()
      },
      selectedChanged(e) {
        this.selected = true
        Object.assign(this.selection, e)
        Object.assign(this.display, e)
        this.selection.software = this.display.software = this.selection.displaysoftware = this.display.displaysoftware = this.display.playersoftware
      },
      updateDisplay() {
        if (this.isEditDisplayFormComplete){
          let fields = this.getChangedFormFields()
          if (Object.keys(fields).length > 0){
            this.update(Object.assign(fields, {st: 'displayid = ' + this.display.displayid}))
                .then(() => {
                  Object.assign(this.selection, fields)
                  toast.displaySuccess('The display has been updated.')
                })
                .catch(() => toast.displayError('There was an error updating this display. Please try again later.'))
          } else {
            toast.displayGeneral('Nothing has changed on this page.')
          }
        }
      }
    },
    computed: {
      isEditDisplayFormComplete() {
        return this.$refs.editDisplay.validate()
      },
      isSelectionMade() {
        return this.selected
      }
    },
    components: {
      DisplayForm,
      DisplaySelect
    },
    mixins: [DisplayMixin]
  }
</script>
