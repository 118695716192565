<template>
    <v-row align="center" justify="start" class="mt-5">
        <v-col cols="6">
            <v-autocomplete
                v-model="selected"
                label="Displays"
                :items="displayList"
                item-text="displayserialid"
                item-value="displayid"
                return-object
                solo
                dense
                required
                clearable
                @change="changeSelectedDisplay"
            ></v-autocomplete>
        </v-col>
    </v-row>
</template>

<script>
    import DisplayModel from "@/store/models/DisplayModel"

    export default {
        name: "display-select",
        data: () => ({
            selected: -1
        }),
        computed: {
            displayList() {
                return DisplayModel.getDisplaysList()
            },
            isSelectionMade(){
                return this.selected !== -1
            }
        },
        methods: {
            clear(){
                //this.$refs.deleteCompany.reset()
                this.selected = -1
            },
            changeSelectedDisplay(){
                this.$emit("selectedChanged", this.selected)
            }
        }
    }
</script>
