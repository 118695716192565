<!--<template></template>-->
<script>
import DisplayModel from '@/store/models/DisplayModel'
import UserModel from '@/store/models/UserModel'
import toast from '@/plugins/notification'
import {addMonthsToDate, formatFullDateForSql} from '@/plugins/datetime'
import {arrayIntersection, generateKey} from '@/plugins/util'

export default {
  name: 'display-mixin',
  data: () => ({
    display: {
      address: '',
      address2: '',
      alias: '',
      brightnesscontroltype: '',
      city: '',
      companyid: '',
      country: 'USA',
      dealerid: '',
      displayserialid: '',
      displaysoftware: '',
      height: '',
      lcd: '0',
      license: 'false',
      licenseterm: null,
      lightsensor: '',
      manufacturer: '',
      maxplayerversion: '',
      mode: 'Physical',
      offsetx: '0',
      offsety: '0',
      pitch: '',
      playermode: 'testing',
      playersoftware: 'SM Infinity',
      playertype: 'HTML5',
      province: '',
      registerkey: '',
      software: '',
      state: '',
      timezone: 'PDT',
      type: 'RGB',
      width: '',
      zip: ''
    },
    companies: [],
    country: 'USA',
    list: []
  }),
  mounted() {
    if (DisplayModel.getDisplaysList().length === 0) {
      DisplayModel.getDisplays({sessionkey: UserModel.getSessionkey()})
          .then(() => {
            this.list = DisplayModel.getDisplaysList()
          })
          .catch(error => {
            console.error(error)
            toast.displayError('There was an error retrieving data. Please try again later')
          })
    } else {
      this.list = DisplayModel.getDisplaysList()
    }
  },
  methods: {
    addSchedule(params) {
      return new Promise((resolve, reject) => {
        DisplayModel.addSchedule(Object.assign(params, {sessionkey: UserModel.getSessionkey()}))
            .then(result => {
              resolve()
            })
            .catch(error => {
              console.error(error)
              toast.displayError('There was an error adding a schedule to this display. Please try again later.')
              reject()
            })
      })
    },
    create(item) {
      let send = this.setCreateData(item)
      return new Promise((resolve, reject) => {
        DisplayModel.addDisplay(Object.assign(send, {sessionkey: UserModel.getSessionkey()}))
            .then(result => {
              this.addSchedule({
                height: item.height,
                width: item.width,
                mode: item.mode,
                type: item.type,
                identifier: item.displayserialid,
                displayserialid: item.displayserialid,
                country: item.country,
                dealerid: send.dealerid
              })
              resolve(result)
            })
            .catch(error => {
              console.error(error)
              toast.displayError('There was an error adding this display. Please try again later.')
              reject()
            })
      })
    },
    isInteger(value) {
      return !isNaN(value) && value.lastIndexOf('.') === -1
    },
    resetDisplay() {
      this.display = {
        address: '',
        address2: '',
        alias: '',
        brightnesscontroltype: '',
        city: '',
        companyid: '',
        country: 'USA',
        dealerid: '',
        displayserialid: '',
        displaysoftware: '',
        height: '',
        lcd: '0',
        license: 'false',
        licenseterm: null,
        lightsensor: '',
        manufacturer: '',
        mode: 'Physical',
        offsetx: '0',
        offsety: '0',
        pitch: '',
        playermode: 'testing',
        playersoftware: 'SM Infinity',
        playertype: 'HTML5',
        playerversion: '',
        province: '',
        registerkey: generateKey(12),
        software: 'SM Infinity',
        state: '',
        timezone: 'PDT',
        type: 'RGB',
        width: '',
        zip: ''
      }
    },
    setCreateData(item) {
      let send = {}
      for (let prop in this.display) {
        if (!!item[prop] && item[prop].toString().length > 0 && item[prop] !== '') {
          if (this.isInteger(item[prop].toString())) {
            item[prop] = parseInt(item[prop])
          }
          send[prop] = item[prop]
        }
      }
      send.brightnesscontroltype = send.brightnesscontroltype.value.toLowerCase()
      send.identifier = item.displayserialid
      if (item.dealerid !== null && item.dealerid.length > 0) {
        send.dealerid = item.dealerid.split("x")[1]
        send.dealer = item.dealer
      }
      if (arrayIntersection(Object.keys(item), ['salesorderid']).length > 0 && item.salesorderid !== null) {
        send.salesorderid = item.salesorderid
      }
      if (send.license === 'true' && !isNaN(send.licenseterm)) {
        send.licenseexpired = formatFullDateForSql(addMonthsToDate(send.licenseterm))
      }
      if (send.country !== 'USA') {
        delete (send.state)
      }
      if (item.playertype.toLowerCase() === "android") {
        send.logicboards = item.logic_boards
      }
      return send
    },
    update(send) {
      return new Promise((resolve, reject) => {
        DisplayModel.updateDisplay(send)
            .then(result => {
              resolve(result)
            })
            .catch(error => {
              console.error(error)
              toast.displayError('There was an error updating this display. Please try again later.')
              reject()
            })
      })
    }
  },
  computed: {
    displayList: {
      get() {
        return this.list
      },
      set(value) {
        this.list = value
      }
    }
  }
}
</script>
