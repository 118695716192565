export const addDisplay = (state, payload) => {
  state.list.push(payload)
  state.list.sort((a, b) => {
    return (a.displayserialid.toString()
             .toLowerCase() < b.displayserialid.toString()
                               .toLowerCase())
           ? -1
           : 1
  })
}

export const removeDisplay = (state, payload) => {
  let index = state.list.findIndex(display => display.displayid === payload)
  if (index !== -1){
    state.list.splice(index, 1)
  }
}

export const setDisplayList = (state, payload) => state.list = payload

export const setSelectedDisplay = (state, payload) => state.selected = payload

export const setSelectedDisplayByIndex = (state, payload) => state.selected = state.list[payload]

export const updateDisplayList = (state, payload) => {
  let displayid = payload.st.split('= ')[1], index = state.list.findIndex(display => display.displayid === displayid)
  if (index !== -1){
    for (var item in payload) {
      if (payload[item] === 'NULL' || payload[item] === null){
        state.list[index][item] = ''
      } else {
        state.list[index][item] = payload[item]
      }
    }
  }
}
