import store from '@/store/store'

export default class CompanyModel {

  static addCompany(params) {
    return new Promise((resolve, reject) =>
      store.dispatch('addCompanyAPI', Object.assign(params, {route: 'company::addcompany'}))
        .then(result => {
          this.addCompanyToList(Object.assign(params, {companyid: result.data.data.companyid}))
            .then(() => {
              resolve(result.data)
            })
        })
        .catch(error => reject(error)))
  }

  static deleteCompany(params) {
    return new Promise((resolve, reject) =>
      store.dispatch('deleteCompanyAPI', Object.assign(params, {route: 'constructor::deletecompany'}))
        .then(result => {
          if (result.data.code !== 200) {
            reject()
          } else {
            this.removeCompanyFromList(params.companyid)
              .then(() => {
                resolve()
              })
          }
        })
        .catch(error => reject(error)))
  }

  static getCompany(params, companyid) {
    return new Promise((resolve, reject) =>
      store.dispatch('getCompanyAPI', Object.assign(params, {
        route: 'company::getcompany'
      }))
        .then(result => {
          this.updateCompanyInList(Object.assign(result.data.data.company, {companyid: companyid}))
            .then(() => {
              resolve(result.data.data.company)
            })
        })
        .catch(error => reject(error)))
  }

  static getCompanyProfiles(params) {
    return new Promise((resolve, reject) =>
      store.dispatch('getCompanyProfilesAPI', Object.assign(params, {
        route: 'company::getcompanyprofile',
        search: 'status = \'active\' and profiletype = \'company\'',
        order: 'businessname asc, companyid asc',
        fields: 'companyid, businessname, firstname, address1, address2, city, state, province, country, zip, phone, email'
      }))
        .then(result => {
          this.setCompanies(result.data.data.companyprofile)
            .then(() => {
              resolve()
            })
        })
        .catch(error => reject(error)))
  }

  static getSpectacularCredits(params, companyid) {
    return new Promise((resolve, reject) =>
      store.dispatch('getSpectacularCreditsAPI', Object.assign(params, {route: 'company::getspectacularcredits'}))
        .then(result => {
          this.updateCompanyInList(Object.assign(result.data.data.spectacularcredits, {companyid: companyid}))
            .then(() => {
              resolve(result.data.data.spectacularcredits)
            })
        })
        .catch(error => reject(error)))
  }

  static updateCompany(params) {
    return new Promise((resolve, reject) =>
      store.dispatch('updateCompanyAPI', Object.assign({}, params, {
        route: 'company::updatecompany'
      }))
        .then(result => {
          let valid = result.constructor === Object && Object.prototype.hasOwnProperty.call(result, "data")
            && Object.prototype.hasOwnProperty.call(result.data, "code")
          if (valid && result.data.code === 200) {
            this.updateCompanyInList(Object.assign(params, {companyid: params.identifier}))
              .then(() => resolve())
          } else {
            reject(result)
          }
        })
        .catch(error => reject(error)))
  }

  static updateCompanyProfile(params, companyid) {
    return new Promise((resolve, reject) =>
      store.dispatch('updateCompanyProfileAPI', Object.assign({}, params, {
        route: 'company::updatecompanyprofile',
        companyid: companyid,
        profiletype: "company"
      }))
        .then(result => {
          if (Object.prototype.hasOwnProperty.call(result.data, "code") && result.data.code === 200) {
            this.updateCompanyInList(Object.assign(params, {companyid: companyid}))
              .then(() => {
                resolve()
              })
          } else {
            reject(result)
          }
        })
        .catch(error => reject(error)))
  }

  static updateSpectacularCredits(params, companyid) {
    return new Promise((resolve, reject) =>
      store.dispatch('updateSpectacularCreditsAPI', Object.assign({}, params, {
        route: 'company::updatespectacularcredits'
      }))
        .then(result => {
          if (Object.prototype.hasOwnProperty.call(result.data, "code") && result.data.code === 200) {
            this.updateCompanyInList(Object.assign(params, {companyid: companyid}))
              .then(() => {
                resolve()
              })
          } else {
            reject(result)
          }
        })
        .catch(error => reject(error)))
  }

  static addCompanyToList(item) {
    return store.dispatch('addCompanyToList', item)
  }

  static getCompanyProfilesList() {
    return store['getters']['getCompanyProfilesFromStore']
  }

  static getSelectedCompany() {
    return store['getters']['getSelectedCompany']
  }

  static removeCompanyFromList(id) {
    return store.dispatch('removeCompanyFromList', id)
  }

  static setCompanies(result) {
    return store.dispatch('setCompanyList', result)
  }

  static setSelectedCompany(company) {
    return store.dispatch('setSelectedCompany', company)
  }

  static setSelectedCompanyByIndex(index) {
    return store.dispatch('setSelectedCompanyByIndex', index)
  }

  static updateCompanyInList(params) {
    return store.dispatch('updateCompany', params)
  }
}
