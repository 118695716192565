<template>
  <v-card>
    <v-card-title class="modal-title-bar headline">
      {{ header }}
      <v-spacer></v-spacer>
      <v-btn dark icon :disabled="!isVerifyMode" @click.prevent="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-layout wrap justify-center fill-height>
        <v-flex xs12 mt-5>
          <template v-if="isVerifyMode">
            <v-layout ml-2>
              <h4>Please verify the following information to continue:</h4>
            </v-layout>
            <v-layout justify-center mt-2>
              <v-flex xs4>
                <label>Display Name:</label>
              </v-flex>
              <v-flex>
                <v-text-field solo readonly v-model="item.alias"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs4>
                <label>Serial ID:</label>
              </v-flex>
              <v-flex>
                <v-text-field solo v-model="displayserialid"></v-text-field>
              </v-flex>
            </v-layout>
          </template>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions class="modal-actions-bar justify-center">
      <v-btn :disabled="!isVerifyMode" @click="closeModal" text rounded class="button-card modal-cancel mr-4">
        Cancel
      </v-btn>
      <v-btn :disabled="!isDisplaySerialIDMatch || !isVerifyMode"
             @click="save"
             text
             rounded
             class="button-card modal-confirm mr-4"
             :loading="isSavingMode">
        Save
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import DisplayMixin from '@/mixins/display-mixin'
import toast from '@/plugins/notification'

export default {
  name: 'AddDisplayVerifyModal',
  data: () => ({
    displayserialid: '',
    status: 'verify',
    title: 'Verify Your Entries'
  }),
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    header() {
      return this.title || 'Message from Website'
    },
    isDisplaySerialIDMatch() {
      return this.item.displayserialid === this.displayserialid
    },
    isSavingMode() {
      return this.status === 'saving'
    },
    isVerifyMode() {
      return this.status === 'verify'
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    save() {
      this.title = 'Saving'
      this.status = 'saving'
      this.create(this.item)
          .then((result) => {
            this.closeModal()
            toast.displaySuccess('Your display was added successfully.')
            this.$emit('done')
          })
          .catch(() => {
            this.closeModal()
            toast.displayError('This display could not be added at this time. Please try again later.')
          })
    }
  },
  mixins: [DisplayMixin]
}
</script>
