import APIResource from '@/store/resources/APIResource'

export const getDealerAPI = ({}, params) => APIResource.callAPI(params)

export const getDefaultsAPI = ({}, params) => APIResource.callAPI(params)

export const getProductAPI = ({}, params) => APIResource.callAPI(params)

export const getSalesOrderAPI = ({}, params) => APIResource.callAPI(params)

export const setDealerList = ({commit}, payload) => commit('setDealerList', payload)

export const setDefaultLists = ({commit}, payload) => commit('setDefaultLists', payload)

export const setSalesOrderList = ({commit}, payload) => commit('setSalesOrderList', payload)
