<!--<template></template>-->
<script>
import CompanyModel from '@/store/models/CompanyModel'
import ProducerModel from '@/store/models/ProducerModel'
import UserModel from '@/store/models/UserModel'
import toast from '@/plugins/notification'
import {arrayIntersection} from '@/plugins/util'


export default {
  name: 'companyMixin',
  data: () => ({
    company: {
      address1: '',
      address2: '',
      businessname: '',
      city: '',
      country: 'USA',
      dealerid: null,
      email: '',
      pointpackageid: '',
      province: '',
      state: null,
      phone: '',
      telephone: '',
      trialperiodlength: 0,
      zip: ''
    },
    list: []
  }),
  mounted() {
    if (CompanyModel.getCompanyProfilesList().length === 0) {
      CompanyModel.getCompanyProfiles({sessionkey: UserModel.getSessionkey()})
          .then(() => {
            this.list = CompanyModel.getCompanyProfilesList()
          })
          .catch(error => {
            console.error(error)
            toast.displayError('There was an error retrieving your data. Please try again later.')
          })
    } else {
      this.list = CompanyModel.getCompanyProfilesList()
    }
  },
  methods: {
    create(item) {
      let send = this.setCreateData(item)
      return new Promise((resolve, reject) => {
        CompanyModel.addCompany(Object.assign(send, {sessionkey: UserModel.getSessionkey()}))
            .then((result) => {
              if (result.code === 610) {
                reject(result)
              } else {
                if (!isNaN(item.salesorderid)) {
                  this.updateSalesOrder(item.salesorderid, {cf_775: result.data.password, cf_774: item.email})
                }
                this.resetCompany()
                resolve(result.data)
              }
            })
      })
    },
    resetCompany() {
      this.company = {
        address1: '',
        address2: '',
        city: '',
        country: 'USA',
        dealerid: null,
        email: '',
        businessname: '',
        phone: '',
        pointpackageid: '',
        province: '',
        state: null,
        telephone: '',
        trialperiodlength: 0,
        zip: ''
      }
    },
    setCreateData(company) {
      let send = {}
      for (let item in company) {
        if (company[item] !== null && company[item].length > 0 && company[item] !== '') {
          send[item] = company[item]
        }
      }
      send.companyname = send.businessname
      send.trialperiodlength = company.trialperiodlength
      send.country = company.country
      send.converttrial = "true"
      if (send.country === 'USA') {
        if (company.state !== null) {
          send.state = company.state
        }
      } else {
        delete (send.state)
        if (company.province.length > 0) {
          send.province = company.province
        }
      }
      if (arrayIntersection(Object.keys(send), ['dealerid']).length > 0 && send.dealerid !== null) {
        send.dealerid = company.dealerid.split("x")[1]
      }
      return send
    },
    update(send) {
      return new Promise((resolve, reject) => {
        CompanyModel.updateCompany(send)
            .then(result => {
              resolve(result)
            })
            .catch(error => {
              console.error(error)
              toast.displayError('There was an error updating this company. Please try again later.')
              reject()
            })
      })
    },
    updateSalesOrder(salesorder_number, params) {
      return new Promise((resolve, reject) => {
        ProducerModel.updateSalesOrder(Object.assign(params, {
          search: "salesorder_no = 'SO" + parseInt(salesorder_number) + "' and sostatus = 'approved'"
        }))
            .then(e => {

            })
            .catch(error => {
              console.error(error)
              toast.displayError('There was an error retrieving the data. Please try again later')
              reject()
            })
      })
    }
  },
  computed: {
    companyList: {
      get() {
        return this.list
      },
      set(value) {
        this.list = value
      }
    }
  }
}
</script>
