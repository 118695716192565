<template>
  <v-row align="center" justify="start" class="mt-6">
    <v-col>
      <v-autocomplete
          v-model="selected"
          label="Companies"
          :items="companyList"
          item-text="businessname"
          item-value="companyid"
          return-object
          solo
          dense
          clearable
          @change="changeSelectedCompany"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import CompanyModel from "@/store/models/CompanyModel"

export default {
  name: "company-select",
  data: () => ({
    selected: -1
  }),
  computed: {
    companyList() {
      return CompanyModel.getCompanyProfilesList()
    },
    isSelectionMade() {
      return this.selected !== -1
    }
  },
  methods: {
    clear() {
      this.selected = -1
    },
    changeSelectedCompany() {
      if (typeof this.selected === "undefined") {
        this.selected = -1
        this.$emit("selectedCleared")
      } else {
        this.$emit("selectedChanged", this.selected)
      }
    }
  }
}
</script>
