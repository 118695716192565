<template>
  <v-row align="center" justify="start">
    <v-col cols="4">
      <v-text-field
          solo
          background-color="black"
          color="white"
          v-model="salesordernumber"
          label="Sales Order #"
          :rules="numberRule"
          hide-details="auto"
          rows="1"
          v-on:keyup.enter="handleEnterKeyPressed"
          type="number">
      </v-text-field>
    </v-col>
    <v-btn fab x-small @click="getSalesOrderInformation">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    <v-col cols="1">
      <v-progress-circular
          v-if="spinner"
          indeterminate
          color="blue"
      ></v-progress-circular>
    </v-col>
  </v-row>
</template>
<script>
import CompanyModel from '@/store/models/CompanyModel'
import ProducerModel from '@/store/models/ProducerModel'
import DisplayModel from '@/store/models/DisplayModel'
import toast from '@/plugins/notification'

export default {
  name: 'sales-order-input',
  data: () => ({
    numberRule: [
      v => {
        if (!v.trim()) {
          return true
        } else if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100000) {
          return true
        }
        return 'Number has to be between 0 and 100000'
      }
    ],
    salesorder: '',
    salesordernumber: '',
    spinner: false,
    valid: false
  }),
  methods: {
    getSalesOrderInformation() {
      this.spinner = true
      return new Promise((resolve, reject) => {
        ProducerModel.getSalesorderDetails({
          fields: 'id',
          search: "salesorder_no = 'SO" + parseInt(this.salesordernumber) + "' and sostatus = 'Approved'"
        })
            .then(e => {
              this.spinner = false
              if (typeof e !== "undefined" && e.constructor === Object) {
                if (e.sostatus.toLowerCase() !== "approved") {
                  toast.displayError('This is not yet an approved sales order. Please try again later')
                } else {
                  resolve(this.populateForm(e))
                }
              } else {
                toast.displayError('There was no data returned. Please check your entries')
              }
            })
            .catch(error => {
              this.spinner = false
              console.error(error)
              toast.displayError('There was an error retrieving the data. Please try again later')
              reject()
            })
      })
    },
    handleEnterKeyPressed() {
      this.getSalesOrderInformation()
    },
    formatDisplayDetails(details) {
      let params = {}, dimensions
      switch (details.length) {
        case 8:
          params.pitch = details[1]
          params.mode = (details[2].toLowerCase() === "v") ? "Virtual" : "Physical"
          params.type = this.formatDisplayType(details[3])
          params.height = details[4]
          params.width = details[5]
          break
        case 5:
          params.pitch = parseInt(details[2].replace("P", ""))
          dimensions = details[4].split("x")
          params.height = parseInt(dimensions[0])
          params.width = parseInt(dimensions[1])
          params.type = this.formatDisplayType(details[3])
          params.mode = "Virtual"
          break
      }
      return params
    },
    formatDisplayType(type) {
      let result = "RGB"
      switch (type) {
        case "GSR":
          result = "MONO - Red"
          break
        case "GSY":
          result = "MONO - Amber"
          break
      }
      return result
    },
    formatEmailFromProjectNumber(project) {
      let email = project.split('-')
      return email[0] + email[1] + '@sminfinity.com'
    },
    formatProjectReferenceFromProjectNumber(project) {
      let reference = project.split('-')
      return reference[0] + reference[1]
    },
    formatTimezone(param) {
      let zone = param.split("(")[0].trim(), response = "PDT"
      switch (zone) {
        case "Central Time":
          response = "CDT"
          break
        case "Eastern Time":
          response = "EDT"
          break
        case "Alaska Time":
          response = "AKDT"
          break
        case "Atlantic Time":
          response = "ADT"
          break
        case "Mountain Time":
          response = "MDT"
          break
        case "Hawaii Time":
          response = "HDT"
          break
      }
      return response
    },
    populateForm(e) {
      let email = this.formatEmailFromProjectNumber(e.cf_646),
          reference = this.formatProjectReferenceFromProjectNumber(e.cf_646),
          dimensions = e.display_details[4].split("x")
      if (e.ship_country.toString().length < 3) {
        if (e.ship_country === null) {
          e.ship_country = "USA"
        } else if (['united states', 'us', 'united states of america'].includes(e.ship_country.toString().toLowerCase())) {
          e.ship_country = "USA"
        }
      }
      if (e.ship_country.toString().length > 3) {
        if (e.ship_country.toLowerCase() === "canada") {
          e.ship_country = "CAN"
        } else {
          e.ship_country = "USA"
        }
      }
      CompanyModel.setSelectedCompany({
        businessname: e.subject,
        companyname: e.subject,
        email: email,
        dealerid: e.account_id,
        salesorderid: this.salesordernumber,
        country: e.ship_country
      })
      DisplayModel.setSelectedDisplay(Object.assign(this.formatDisplayDetails(e.display_details), {
        displayserialid: reference,
        alias: e.subject,
        manufacturer: "Vantage LED",
        dealer: e.account_name,
        dealerid: e.account_id,
        salesorderid: e.id.split("x")[1],
        timezone: this.formatTimezone(e.cf_654),
        country: e.ship_country
      }))
      this.$emit('setCompanyFormFromSalesOrderDetails')
      this.$emit('setDisplayFormFromSalesOrderDetails')
    }
  }
}
</script>
