import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"modal-title-bar headline"},[_vm._v(" "+_vm._s(_vm.header)+" "),_c(VSpacer),_c(VBtn,{attrs:{"dark":"","icon":"","disabled":!_vm.isVerifyMode},on:{"click":function($event){$event.preventDefault();return _vm.closeModal.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VLayout,{attrs:{"wrap":"","justify-center":"","fill-height":""}},[_c(VFlex,{attrs:{"xs12":"","mt-5":""}},[(_vm.isVerifyMode)?[_c(VLayout,{attrs:{"ml-2":""}},[_c('h4',[_vm._v("Please verify the following information to continue:")])]),_c(VLayout,{attrs:{"justify-center":"","mt-2":""}},[_c(VFlex,{attrs:{"xs4":""}},[_c('label',[_vm._v("Display Name:")])]),_c(VFlex,[_c(VTextField,{attrs:{"solo":"","readonly":""},model:{value:(_vm.item.alias),callback:function ($$v) {_vm.$set(_vm.item, "alias", $$v)},expression:"item.alias"}})],1)],1),_c(VLayout,[_c(VFlex,{attrs:{"xs4":""}},[_c('label',[_vm._v("Serial ID:")])]),_c(VFlex,[_c(VTextField,{attrs:{"solo":""},model:{value:(_vm.displayserialid),callback:function ($$v) {_vm.displayserialid=$$v},expression:"displayserialid"}})],1)],1)]:_vm._e()],2)],1)],1),_c(VCardActions,{staticClass:"modal-actions-bar justify-center"},[_c(VBtn,{staticClass:"button-card modal-cancel mr-4",attrs:{"disabled":!_vm.isVerifyMode,"text":"","rounded":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"button-card modal-confirm mr-4",attrs:{"disabled":!_vm.isDisplaySerialIDMatch || !_vm.isVerifyMode,"text":"","rounded":"","loading":_vm.isSavingMode},on:{"click":_vm.save},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c(VIcon,{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}])},[_vm._v(" Save ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }