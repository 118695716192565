import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"align":"center","justify":"start"}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"solo":"","background-color":"black","color":"white","label":"Sales Order #","rules":_vm.numberRule,"hide-details":"auto","rows":"1","type":"number"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnterKeyPressed.apply(null, arguments)}},model:{value:(_vm.salesordernumber),callback:function ($$v) {_vm.salesordernumber=$$v},expression:"salesordernumber"}})],1),_c(VBtn,{attrs:{"fab":"","x-small":""},on:{"click":_vm.getSalesOrderInformation}},[_c(VIcon,[_vm._v("mdi-magnify")])],1),_c(VCol,{attrs:{"cols":"1"}},[(_vm.spinner)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"blue"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }