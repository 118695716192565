<template>
  <v-app>
    <v-app-bar app clipped-right
               class="purple darken-2"
               >
      <v-toolbar-title><span style="color:white;" class="px-5">Constructor Portal v1.3.9</span></v-toolbar-title>
      <v-divider
          class="mx-4 ml-5"
          vertical
      ></v-divider>
      <v-toolbar-items>
        <v-btn-toggle
            v-model="toggle_exclusive"
            mandatory
            class="my-0 py-0"
        >
          <v-btn text @click="setView('companies')" class="my-2">Company</v-btn>
          <v-btn text @click="setView('displays')" class="my-2">Display</v-btn>
        </v-btn-toggle>
      </v-toolbar-items>
      <v-divider
          class="mx-4"
          vertical
      ></v-divider>
      <v-spacer/>
      <v-btn text @click="logoutUser" color="white">Logout</v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-window>
          <v-row dense>
            <v-col cols="12">
              <v-tabs vertical>
                <v-tabs-slider></v-tabs-slider>
                <v-tab>Add</v-tab>
                <v-tab disabled>Edit</v-tab>
                <v-tab>Delete</v-tab>
                <v-tab-item>
                  <template v-if="isCompanies">
                    <add-company></add-company>
                  </template>
                  <template v-else>
                    <add-display></add-display>
                  </template>
                </v-tab-item>
                <v-tab-item>
                  <template v-if="isCompanies">
                    <edit-company></edit-company>
                  </template>
                  <template v-else>
                    <edit-display></edit-display>
                  </template>
                </v-tab-item>
                <v-tab-item>
                  <template v-if="isCompanies">
                    <delete-company></delete-company>
                  </template>
                  <template v-else>
                    <delete-display></delete-display>
                  </template>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-window>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AddCompanyView from '@/layouts/views/add-company'
import AddDisplayView from '@/layouts/views/add-display'
import DeleteCompanyView from '@/layouts/views/delete-company'
import DeleteDisplayView from '@/layouts/views/delete-display'
import EditCompanyView from '@/layouts/views/edit-company'
import EditDisplayView from '@/layouts/views/edit-display'
import ProducerModel from '@/store/models/ProducerModel'
import toast from '@/plugins/notification'
import UserModel from '@/store/models/UserModel'
import CompanyModel from "@/store/models/CompanyModel"

export default {
  name: "page",
  data: () => ({
    left: false,
    toggle_exclusive: undefined,
    view: ''
  }),
  mounted() {
    this.getDefaults().then(() => {
      this.view = 'companies'
    })
  },
  methods: {
    getCompanies() {
      return new Promise((resolve, reject) => {
        CompanyModel.getCompanyProfiles().then(() => {
          resolve()
        }).catch(error => {
          console.error(error)
          toast.displayError("There was an error retrieving the data. Please try again later")
          reject()
        })
      })
    },
    getDefaults() {
      return new Promise((resolve, reject) => {
        ProducerModel.getDefaults().then(() => {
          this.selected_menu = 'add-company'
          resolve()
        }).catch(error => {
          console.error(error)
          toast.displayError("There was an error retrieving the data. Please try again later")
          reject()
        })
      })
    },
    logoutUser() {
      UserModel.logout().then(() => {
        this.$router.push("/")
      }).catch(error => {
        console.error(error)
        toast.displayError("There was an error logging out. Please try again later.")
      })
    },
    setView(e) {
      this.view = e
    }
  },
  computed: {
    isCompanies() {
      return this.view === "companies"
    }
  },
  components: {
    'add-company': AddCompanyView,
    'add-display': AddDisplayView,
    'delete-company': DeleteCompanyView,
    'delete-display': DeleteDisplayView,
    'edit-company': EditCompanyView,
    'edit-display': EditDisplayView
  }
}
</script>
