export const setDealerList = (state, payload) => state.dealers = payload.accounts

export const setDefaultLists = (state, payload) => {
  state.countries = payload.countries
  state.spectacularpackages = payload.spectacularpackages
  state.states = payload.states
  state.playerversions = payload.playerversions
}

export const setSalesOrderList = (state, payload) => state.salesorders = payload.salesorders
