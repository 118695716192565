export const addMonthsToDate = (months) => {
  let now = new Date()
  now.setMonth(now.getMonth() + months)
  return now
}

export const formatFullDateForSql = (date) => {
  let parsed = date.toJSON().split("T")
  return parsed[0]
}

export const getDst = () => {
  return new Date().getTimezoneOffset() < stdTimezoneOffset()
}

export const getOffset = () => {
  let x = (getDst()) ? 420 : 480, now = new Date(), offset = x - now.getTimezoneOffset()
  return offset
}

export const stdTimezoneOffset = () => {
  let year = new Date().getFullYear(), jan = new Date(year, 0, 1), jul = new Date(year, 6, 1)
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
}
