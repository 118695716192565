import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"updateCompany",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VLayout,[_c(VFlex,{attrs:{"xs12":""}},[_c(VToolbar,{attrs:{"color":"#f8f9fa","dark":""}},[_c('company-select',{on:{"selectedChanged":_vm.selectedChanged,"selectedCleared":_vm.selectedCleared}}),_c(VSpacer),_c(VBtn,{staticClass:"success",attrs:{"disabled":!_vm.isSelectionMade},on:{"click":_vm.edit}},[_vm._v("Save")])],1),_c(VCard,[_c('company-form',{attrs:{"company":_vm.company}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }