<template>
  <v-row class="ma-4">
    <v-col cols="6">
      <v-text-field
          v-model="company.businessname"
          :rules="companynameRules"
          :counter="100"
          label="Company Name"
      ></v-text-field>
      <v-text-field
          v-model="company.email"
          :rules="emailRules"
          label="E-mail"
          required
      ></v-text-field>
      <v-text-field
          v-model="company.address1"
          counter="100"
          label="Address"
      ></v-text-field>
      <v-text-field
          v-model="company.address2"
          counter="100"
          hint="address (continued)"
          persistent-hint
      ></v-text-field>
      <v-row>
        <v-col cols="8">
          <v-text-field
              v-model="company.city"
              counter="100"
              label="City"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
              v-model="company.zip"
              counter="20"
              label="Zip"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-select
          v-model="company.country"
          :items="countryList"
          item-text="name"
          item-value="abbreviation"
          label="Country"
          required
      ></v-select>
      <v-select v-if="isState"
          v-model="company.state"
          :items="stateList"
          item-text="state"
          item-value="state"
          label="State/Province"
      ></v-select>
      <v-text-field v-else
          v-model="company.province"
          counter="100"
          label="Province"
      ></v-text-field>
      <v-text-field
          v-model="company.phone"
          counter="100"
          label="Telephone"
          hint="x-xxx-xxx-xxxx"
          persistent-hint
          @paste.prevent
          @keypress="validateTelephone"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-select
          v-model="packagetype"
          :items="packagetypes"
          item-text="name"
          item-value="value"
          label="Package Type"
          return-object
          required
          @change="changePackageFilter"
      ></v-select>
      <v-select
          v-model="company.pointpackageid"
          :rules="packageRules"
          :items="filtered_packages"
          item-text="packagename"
          item-value="packageid"
          label="Package"
          required
      ></v-select>
      <v-text-field
          v-model="company.trialperiodlength"
          label="Trial Period"
          hint="months"
          persistent-hint
          @keypress="validateDigit"
      ></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
  import ProducerModel from '@/store/models/ProducerModel'

  export default {
    name: 'company-form',
    data: () => ({
      companynameRules: [
        v => !!v || 'Name is required',
        v => ((v && v.toString()) || '').length <= 100 || 'Name must be less than 100 characters'
      ],
      country: 'USA',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => ((v && v.toString()) || '').length <= 100 || 'Max 100 characters',
        v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || 'Invalid e-mail.'
        }
      ],
      filtered_packages: [],
      package_list: [],
      packageRules: [
        v => !!v || 'Package is required'
      ],
      packagetype: 'standard',
      packagetypes: [
        {value: 'standard', name: 'Standard'},
        {value: 'content', name: 'Content Only'},
        {value: 'conversion', name: 'Conversion'},
        {value: 'reseller', name: 'Reseller'}
      ],
      phoneRules: [
        v => (v.toString() || '').length <= 100 || 'Max 100 characters',
        v => {
          const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
          return pattern.test(v) || 'Invalid phone number.'
        }
      ],
      valid: false
    }),
    props: {
      company: {
        type: Object,
        required: true
      }
    },
    mounted() {
      this.package_list = ProducerModel.getSpectacularPackages()
      this.spectacularPackages = 'standard'
    },
    methods: {
      changePackageFilter() {
        this.spectacularPackages = this.packagetype.value
      },
      reset() {
        this.country = 'USA'
        this.spectacularPackages = 'standard'
      },
      validateDigit(e){
        if (!('0123456789').includes(e.key) || e.ctrlKey || e.altKey) {
          e.preventDefault()
        }
      },
      validateTelephone(e){
        if (!('0123456789+-').includes(e.key) || e.ctrlKey || e.altKey) {
          e.preventDefault()
        }
      }
    },
    computed: {
      companyList: {
        get() {
          return this.list
        },
        set(value) {
          this.list = value
        }
      },
      countryList() {
        return ProducerModel.getCountries()
      },
      spectacularPackageList() {
        return ProducerModel.getSpectacularPackages()
      },
      stateList() {
        return ProducerModel.getStates()
      },
      isState() {
        return this.company.country === 'USA'
      },
      spectacularPackages: {
        get() {
          return this.filtered_packages
        },
        set(value) {
          if (this.package_list.length === 0){
            this.package_list = ProducerModel.getSpectacularPackages()
          }
          this.filtered_packages = this.package_list.filter(item => item.type === value)
        }
      }
    }
  }
</script>
