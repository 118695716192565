import Vue from 'vue'
import Toasted from 'vue-toasted'

let Options = {
  position: 'top-left',
  duration: 5000,
  action: {
    text: 'Dismiss',
    class: 'dismiss-btn-color',
    onClick: (e, toastObject) => {
      toastObject.goAway(0)
    }
  },
  className: 'dismiss-color',
  fullWidth: false,
  fitToScreen: false,
  containerClass: 'toast-container-wide',
  singleton: true
}

Vue.use(Toasted, Options)

export class Notification {
  constructor(){
  }
  
  static displayError(message) {
    Vue.toasted.error(message, {duration: 30000})
  }
  
  static displaySuccess(message) {
    Vue.toasted.success(message)
  }
  
  static displayInfo(message) {
    Vue.toasted.info(message)
  }
  
  static displayGeneral(message) {
    Vue.toasted.show(message)
  }
  
  static displayExtendedInfo(message) {
    Vue.toasted.info(message, {duration: 30000})
  }
}

export default Notification
