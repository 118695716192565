<template>
  <v-card>
    <v-card-title class="modal-title-bar headline">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn dark icon @click.prevent="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-layout wrap justify-center fill-height>
        <v-flex xs12 mt-5>
          <template v-if="showConfirm">
            <v-row class="mx-4 my-2">
              <h4>Please verify the following information to continue:</h4>
            </v-row>
            <v-row class="mt-2" no-gutters>
              <v-col cols="3" align-self="auto">
                <label>Company Name:</label>
              </v-col>
              <v-col>
                <v-text-field solo readonly v-model="item.businessname"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <label>Email:</label>
              </v-col>
              <v-col>
                <v-text-field solo v-model="email"></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-if="showMessage">
            <v-layout justify-center mt-2>
              <v-flex xs4>
                <label>Initial Login Email:</label>
              </v-flex>
              <v-flex>
                <v-text-field solo readonly v-model="item.email"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs4>
                <label>Initial Password:</label>
              </v-flex>
              <v-flex>
                <v-text-field solo readonly v-model="password"></v-text-field>
              </v-flex>
            </v-layout>
          </template>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions class="modal-actions-bar justify-center">
      <v-btn :disabled="showSpinner" @click="closeModal" text rounded class="button-card modal-cancel mr-4">
        {{ closeButtonText }}
      </v-btn>
      <v-btn :disabled="!isEmailMatch || !showConfirm"
             @click="save"
             text
             rounded
             class="button-card modal-confirm mr-4"
             :loading="showSpinner">
        Save
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
        <template v-if="isAdded">
          <v-icon color="success" class="ml-2">{{ icon.valid }}</v-icon>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import CompanyMixin from '@/mixins/company-mixin'
import {THUMB_UP_OUTLINE} from '@/assets/constants'
import toast from '@/plugins/notification'

export default {
  name: 'AddCompanyVerifyModal',
  data: () => ({
    closeButtonText: 'Cancel',
    email: '',
    icon: {
      valid: THUMB_UP_OUTLINE
    },
    isAdded: false,
    password: '',
    status: 'confirm',
    title: 'Verify Your Entries'
  }),
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    isEmailMatch() {
      return this.item.email === this.email
    },
    showConfirm() {
      return this.status === 'confirm'
    },
    showMessage() {
      return this.status === 'message'
    },
    showSpinner() {
      return this.status === 'saving'
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    reset() {
      this.resetCompany()
      this.$emit('reset')
    },
    save() {
      this.status = 'saving'
      this.create(this.item)
          .then((result) => {
            this.closeButtonText = 'Close'
            this.isAdded = true
            this.title = 'Added!'
            this.status = 'message'
            this.password = result.password
            this.reset()
          })
          .catch((err) => {
            this.closeModal()
            toast.displayError('This email already exists in our system. Please check your entry.')
          })
    }
  },
  mixins: [CompanyMixin]
}
</script>
