import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/layouts/login'
import page from '@/layouts/page'

Vue.use(VueRouter)

export default new VueRouter({
  routes: [
    {
      path: '/',
      component: login
    },
    {
      path: '/page',
      name: 'Page',
      component: page
    },
    {
      path: '*',
      name: 'Welcome',
      component: {
        template: '<h1 style="text-align: center; vertical-align: middle;">Page Not Found</h1>'
      }
    }
  ],
  mode: 'history'
})
