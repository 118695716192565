import * as producerGetters from './producer/getters'
import * as producerMutations from './producer/mutations'
import * as producerActions from './producer/actions'

const state = {
  countries: [],
  dealers: [],
  salesorders: [],
  spectacularpackages: [],
  states: [],
  playerversion: -1,
  playerversions: []
}

const getters = producerGetters
const mutations = producerMutations
const actions = producerActions

export default {
  state,
  getters,
  mutations,
  actions
}
