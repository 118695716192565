<template>
  <v-app>
    <v-main>
      <transition name="component-fade" mode="out-in" appear>
        <router-view/>
      </transition>
    </v-main>
  </v-app>
</template>
<script>
  export default {
    name: 'App'
  }
</script>
<style lang="scss">
  @import './assets/scss/app.scss';
</style>
