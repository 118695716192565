import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"addDisplay",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('modals-container',{on:{"done":_vm.clearForm}}),_c(VLayout,[_c(VFlex,{attrs:{"xs12":""}},[_c(VToolbar,{attrs:{"color":"#f8f9fa","dark":""}},[_c('sales-order-input',{on:{"setDisplayFormFromSalesOrderDetails":_vm.updateDisplayForm}}),_c(VSpacer),_c(VBtn,{staticClass:"success",on:{"click":_vm.showConfirmationModal}},[_vm._v("Save")])],1),_c(VCard,[_c('display-form',{attrs:{"display":_vm.display,"mode":'add'}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }