import APIResource from '@/store/resources/APIResource'

export const addDisplayAPI = ({}, params) => APIResource.callAPI(params)

export const addDisplayToList = ({commit}, payload) => commit('addDisplay', payload)

export const deleteDisplayAPI = ({}, params) => APIResource.callAPI(params)

export const getDisplaysAPI = ({}, params) => APIResource.callAPI(params)

export const removeDisplayFromList = ({commit}, payload) => commit('removeDisplay', payload)

export const setDisplayList = ({commit}, payload) => commit('setDisplayList', payload)

export const setSelectedDisplay = ({commit}, payload) => commit('setSelectedDisplay', payload)

export const setSelectedDisplayByIndex = ({commit}, payload) => commit('setSelectedDisplayByIndex', payload)

export const updateDisplayAPI = ({}, params) => APIResource.callAPI(params)

export const updateDisplayList = ({commit}, payload) => commit('updateDisplayList', payload)
