<template>
  <v-form v-model="valid" ref="updateCompany">
    <v-layout>
      <v-flex xs12>
        <v-toolbar color="#f8f9fa" dark>
          <company-select v-on:selectedChanged="selectedChanged"
                          v-on:selectedCleared="selectedCleared"></company-select>
          <v-spacer></v-spacer>
          <v-btn class="success" :disabled="!isSelectionMade" @click="edit">Save</v-btn>
        </v-toolbar>
        <v-card>
          <company-form :company="company"></company-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import CompanyForm from '@/components/forms/company-form'
import CompanyMixin from '@/mixins/company-mixin'
import CompanyModel from '@/store/models/CompanyModel'
import CompanySelect from '@/components/selects/company-select'
import {intersection} from '@/plugins/util'
import {checkFieldsForNull, getDifferenceOfObjects} from '@/plugins/validator'
import toast from '@/plugins/notification'

export default {
  name: 'edit-company',
  data: () => ({
    isSaving: false,
    pointpackageid: '-1',
    selected: false,
    valid: false
  }),
  methods: {
    getChangedFormFields() {
      let x = this.list.filter(company => company.companyid === this.company.companyid)[0],
          changed = getDifferenceOfObjects(x, this.company)
      if (Object.prototype.hasOwnProperty.call(changed, "country")) {
        if (changed.country === 'USA') {
          changed.province = 'NULL'
          changed.state = this.company.state
        } else {
          changed.province = this.company.province
          changed.state = 'NULL'
        }
      }
      if (Object.prototype.hasOwnProperty.call(changed, "zip") && isNaN(changed.zip)) {
        changed.zip = 'NULL'
      }
      if (Object.prototype.hasOwnProperty.call(changed, "pointpackageid")) {
        changed.pointpackageid = changed.pointpackageid.toString()
      }
      if (Object.prototype.hasOwnProperty.call(changed, "dealerid")) {
        delete changed.dealerid
      }
      // TODO: each time drop down change occurs, the fields are now locked to that company w/ instant update
      return checkFieldsForNull(changed)
    },
    getCompany(companyid) {
      return new Promise((resolve, reject) => {
        CompanyModel.getCompany({
          fields: 'trialperiodlength',
          st: 'companyid = ' + companyid
        })
            .then((result) => {
              this.company.trialperiodlength = parseInt(result.trialperiodlength)
              let x = this.list.filter(company => company.companyid === companyid)[0]
              x.trialperiodlength = this.company.trialperiodlength
              resolve()
            })
            .catch(error => {
              reject()
            })
      })
    },
    getSpectacularCredits(companyid) {
      return new Promise((resolve, reject) => {
        CompanyModel.getSpectacularCredits({
          fields: 'pointpackageid',
          st: 'id = ' + companyid
        })
            .then((result) => {
              this.company.pointpackageid = result.pointpackageid.toString()
              let x = this.list.filter(company => company.companyid === companyid)[0]
              x.pointpackageid = this.company.pointpackageid
              resolve()
            })
            .catch(error => {
              reject()
            })
      })
    },
    isUpdateCompany(fields) {
      let is = intersection(Object.keys(fields), [
        'companyname',
        'address1',
        'address2',
        'city',
        'state',
        'zip',
        'province',
        'country',
        'phone',
        'email',
        'trialperiodlength'
      ])
      return is.length > 0
    },
    isUpdateCompanyProfile(fields) {
      let is = intersection(Object.keys(fields), [
        'businessname',
        'address1',
        'address2',
        'city',
        'state',
        'zip',
        'province',
        'country',
        'phone',
        'email'
      ])
      return is.length > 0
    },
    isUpdateSpectacularCredits(fields) {
      let is = intersection(Object.keys(fields), ['pointpackageid'])
      return is.length > 0
    },
    selectedChanged(e) {
      this.selected = true
      let promises = []
      if (!Object.prototype.hasOwnProperty.call(e, "pointpackageid")) {
        promises.push(this.getSpectacularCredits(e.companyid))
      }
      if (!Object.prototype.hasOwnProperty.call(e, "trialperiodlength")) {
        promises.push(this.getCompany(e.companyid))
      }
      if (promises.length > 0) {
        Promise.all(promises)
            .then(() => {
              if (Object.prototype.hasOwnProperty.call(e, "trialperiodlength") && isNaN(e.trialperiodlength)) {
                e.trialperiodlength = 0
              }
              Object.assign(this.company, e)
            })
            .catch(error => {
              toast.displayError('There was an error retrieving data for this company. Please try again later.')
            })
      } else {
        this.company = e
      }
    },
    selectedCleared() {
      //  TODO: how to "reset" fields now that cleared has been pressed?
    },
    edit() {
      if (this.isEditCompanyFormComplete) {
        let fields = this.getChangedFormFields()
        if (Object.keys(fields).length > 0) {
          this.isSaving = true
          let promises = []
          if (Object.prototype.hasOwnProperty.call(fields, "businessname")) {
            fields.companyname = fields.businessname
          }
          if (this.isUpdateSpectacularCredits(fields)) {
            promises.push(this.updateSpectacularCredits(fields))
          }
          if (this.isUpdateCompanyProfile(fields)) {
            promises.push(this.updateCompanyProfile(fields))
          }
          if (this.isUpdateCompany(fields)) {
            if (Object.prototype.hasOwnProperty.call(fields, "businessname")) {
              delete fields.businessname
            }
            promises.push(this.updateCompany(fields))
          }
          Promise.all(promises)
              .then(() => {
                if (Object.prototype.hasOwnProperty.call(fields, "businessname") || Object.prototype.hasOwnProperty.call(fields, "companyname")) {
                  let index = this.companyList.findIndex(({companyid}) => companyid === this.company.companyid)
                  this.company.businessname = this.companyList[index].businessname = fields.companyname
                }
                if (Object.prototype.hasOwnProperty.call(fields, "pointpackageid")) {
                  this.company.pointpackageid = fields.pointpackageid
                }
                this.valid = true
                this.isSaving = false
                toast.displaySuccess('The company has been updated.')
              })
              .catch(error => {
                toast.displayError('There was an error updating this company. Please try again later.')
              })
        } else {
          toast.displayGeneral('Nothing has changed on this page.')
        }
      }
    },
    updateCompany(fields) {
      return new Promise((resolve, reject) => {
        CompanyModel.updateCompany(Object.assign(fields, {identifier: this.company.companyid}))
            .then(() => resolve())
            .catch((error) => reject(error))
      })
    },
    updateCompanyProfile(fields) {
      return new Promise((resolve, reject) => {
        CompanyModel.updateCompanyProfile(fields, this.company.companyid)
            .then(() => resolve())
            .catch((error) => reject(error))
      })
    },
    updateSpectacularCredits(fields) {
      return new Promise((resolve, reject) => {
        CompanyModel.updateSpectacularCredits(fields, this.company.companyid)
            .then(() => resolve())
            .catch((error) => reject(error))
      })
    }
  },
  computed: {
    isEditCompanyFormComplete() {
      return this.$refs.updateCompany.validate()
    },
    isSelectionMade() {
      return this.selected
    }
  },
  components: {
    CompanySelect,
    CompanyForm
  },
  mixins: [CompanyMixin]
}
</script>
