<template>
  <v-card>
    <v-card-title class="modal-title-bar headline">
      Confirmation
      <v-spacer></v-spacer>
      <v-btn :disabled='isProcessing' dark icon @click.prevent="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-layout wrap justify-center fill-height ma-3>
        <label>Are you sure you want to delete ({{ display.displayserialid }}) {{ display.alias }} ?</label>
      </v-layout>
    </v-card-text>
    <v-card-actions class="modal-actions-bar justify-center">
      <v-btn :disabled='isProcessing' @click="closeModal" text rounded class="button-card modal-cancel mr-4">
        No
      </v-btn>
      <v-btn :disabled='isProcessing'
             @click="save"
             text
             rounded
             class="button-card modal-confirm mr-4"
             :loading="isProcessing">
        Yes
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import DisplayModel from '@/store/models/DisplayModel'
import UserModel from '@/store/models/UserModel'
import toast from '@/plugins/notification'

export default {
  name: 'DeleteDisplayConfirmModal',
  props: {
    display: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isProcessing: false
  }),
  methods: {
    closeModal() {
      this.$emit('close')
    },
    remove(id) {
      return new Promise((resolve, reject) => {
        // todo: this still needs to have the display removed from the displayControllers table
        DisplayModel.deleteDisplay({sessionkey: UserModel.getSessionkey(), identifier: id})
            .then(() => {
              resolve()
            })
            .catch(error => {
              reject()
            })
      })
    },
    save() {
      this.isProcessing = true
      this.remove(this.display.displayid)
          .then(() => {
            this.$emit('done')
            this.closeModal()
            toast.displayInfo('The display was deleted.')
          })
          .catch(error => {
            this.closeModal()
            toast.displayError('There was an error deleting this display. Please try again later')
          })
    }
  }
}
</script>
