import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VWindow } from 'vuetify/lib/components/VWindow';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VAppBar,{staticClass:"purple darken-2",attrs:{"app":"","clipped-right":""}},[_c(VToolbarTitle,[_c('span',{staticClass:"px-5",staticStyle:{"color":"white"}},[_vm._v("Constructor Portal v1.3.9")])]),_c(VDivider,{staticClass:"mx-4 ml-5",attrs:{"vertical":""}}),_c(VToolbarItems,[_c(VBtnToggle,{staticClass:"my-0 py-0",attrs:{"mandatory":""},model:{value:(_vm.toggle_exclusive),callback:function ($$v) {_vm.toggle_exclusive=$$v},expression:"toggle_exclusive"}},[_c(VBtn,{staticClass:"my-2",attrs:{"text":""},on:{"click":function($event){return _vm.setView('companies')}}},[_vm._v("Company")]),_c(VBtn,{staticClass:"my-2",attrs:{"text":""},on:{"click":function($event){return _vm.setView('displays')}}},[_vm._v("Display")])],1)],1),_c(VDivider,{staticClass:"mx-4",attrs:{"vertical":""}}),_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"white"},on:{"click":_vm.logoutUser}},[_vm._v("Logout")])],1),_c(VMain,[_c(VContainer,[_c(VWindow,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTabs,{attrs:{"vertical":""}},[_c(VTabsSlider),_c(VTab,[_vm._v("Add")]),_c(VTab,{attrs:{"disabled":""}},[_vm._v("Edit")]),_c(VTab,[_vm._v("Delete")]),_c(VTabItem,[(_vm.isCompanies)?[_c('add-company')]:[_c('add-display')]],2),_c(VTabItem,[(_vm.isCompanies)?[_c('edit-company')]:[_c('edit-display')]],2),_c(VTabItem,[(_vm.isCompanies)?[_c('delete-company')]:[_c('delete-display')]],2)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }