import store from '@/store/store'
import UserModel from './UserModel'

export default class ProducerModel {

  static getDealers(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getDealerAPI', Object.assign(params, {
        route: 'constructor::getaccount', sessionkey: UserModel.getSessionkey()
      }))
        .then(result => {
          this.setDealerList(result.data.data)
            .then(() => {
              resolve()
            })
        })
        .catch(error => reject(error)))
  }

  static getDefaults(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getDefaultsAPI', Object.assign(params, {
        route: 'constructor::getdefaults'
      }))
        .then(result => {
          this.setDefaults(result.data.data)
            .then(() => {
              resolve()
            })
        })
        .catch(error => reject(error)))
  }

  /*static getProduct(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getProductAPI', Object.assign(params, {
        fn: 'getproduct',
        rt: 'json',
        sessionkey: UserModel.getSessionkey()
      }))
           .then(result => {
             resolve(result.data.data)
           })
           .catch(error => reject(error)))
  }*/

  static getSalesorderDetails(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getSalesOrderAPI', Object.assign(params, {
        route: 'constructor::getsalesorder',
        sessionkey: UserModel.getSessionkey()
      }))
        .then(result => {
          resolve(result.data.data.salesorder[0])
        })
        .catch(error => reject(error)))
  }

  static updateSalesOrder(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getSalesOrderAPI', Object.assign(params, {
        route: 'constructor::updatesalesorder',
        sessionkey: UserModel.getSessionkey()
      }))
        .then(result => {
          resolve()
        })
        .catch(error => reject(error)))
  }

  static getCountries() {
    return store['getters']['getCountriesFromStore']
  }

  static getDealerList() {
    return store['getters']['getDealersFromStore']
  }

  static getPlayerVersions() {
    return store['getters']['getPlayerVersionsFromStore']
  }

  static getSalesorderList() {
    return store['getters']['getSalesOrdersFromStore']
  }

  static getSpectacularPackages() {
    return store['getters']['getSpectacularPackagesFromStore']
  }

  static getStates() {
    return store['getters']['getStatesFromStore']
  }

  static setDealerList(result) {
    return store.dispatch('setDealerList', result)
  }

  static setDefaults(result) {
    return store.dispatch('setDefaultLists', result)
  }

  static setSalesOrderList(result) {
    return store.dispatch('setSalesOrderList', result)
  }
}
