import store from '@/store/store'

export default class DisplayModel {

  static addDisplay(params) {
    return new Promise((resolve, reject) =>
      store.dispatch('addDisplayAPI', Object.assign(params, {route: 'display::adddisplay', rt: 'json'}))
        .then(result => {
          if (Object.prototype.hasOwnProperty.call(result.data, "code") && result.data.code === 200) {
            this.addDisplayToList(Object.assign(params, {displayid: result.data.data.displayid}))
              .then(() => {
                resolve(result.data.data.displayid)
              })
          } else {
            reject(result)
          }
        })
        .catch(error => reject(error)))
  }

  static addDviBoard(params) {
    return new Promise((resolve, reject) =>
      store.dispatch('addDisplayAPI', Object.assign(params, {route: 'display::adddisplaydviboardentry'}))
        .then(result => {
          if (Object.prototype.hasOwnProperty.call(result.data, "code") && result.data.code === 200) {
            resolve()
          } else {
            reject(result)
          }
        })
        .catch(error => reject(error)))
  }

  static addLogicBoard(params) {
    return new Promise((resolve, reject) =>
      store.dispatch('addDisplayAPI', Object.assign(params, {route: 'display::adddisplaylogicboardentry'}))
        .then(result => {
          if (Object.prototype.hasOwnProperty.call(result.data, "code") && result.data.code === 200) {
            resolve()
          } else {
            reject(result)
          }
        })
        .catch(error => reject(error)))
  }

  static addSchedule(params) {
    return new Promise((resolve, reject) =>
      store.dispatch('addDisplayAPI', Object.assign(params, {route: 'constructor::addinitialschedule', rt: 'json'}))
        .then(result => {
          resolve()
        })
        .catch(error => reject(error)))
  }

  static deleteDisplay(params) {
    return new Promise((resolve, reject) =>
      store.dispatch('deleteDisplayAPI', Object.assign(params, {route: 'constructor::deletedisplay'}))
        .then(result => {
          if (result.data.code !== 200) {
            reject()
          } else {
            this.removeDisplayFromList(params.displayid)
              .then(() => {
                resolve()
              })
          }
        })
        .catch(error => reject(error)))
  }

  static getDisplays(params) {
    return new Promise((resolve, reject) =>
      store.dispatch('getDisplaysAPI', Object.assign(params, {
        route: 'display::getdisplay',
        search: 'active = \'true\'',
        order: 'displayserialid asc',
        fields: 'displayserialid, displayid, alias, address, address2, city, state, zip, province, country, ' +
          'timezone, companyid, dealerid, height, width, offsetx, offsety, pitch, mode, type, lcd, playertype, playersoftware, playermode, ' +
          'manufacturer, maxplayerversion, lightsensor, registerkey, license, licenseterm, licenseexpired'
      }))
        .then(result => {
          this.setDisplays(result.data.data.display)
            .then(() => {
              resolve()
            })
        })
        .catch(error => reject(error)))
  }

  static updateDisplay(params) {
    return new Promise((resolve, reject) =>
      store.dispatch('updateDisplayAPI', Object.assign(params, {route: 'display::updatedisplay'}))
        .then(result => {
          if (Object.prototype.hasOwnProperty.call(result.data, "code") && result.data.code === 200) {
            this.updateDisplayInList(params)
              .then(() => {
                resolve()
              })
          } else {
            reject(result)
          }
        })
        .catch(error => reject(error)))
  }

  static addDisplayToList(item) {
    return store.dispatch('addDisplayToList', item)
  }

  static getDisplaysList() {
    return store['getters']['getDisplaysFromStore']
  }

  static getSelectedDisplay() {
    return store['getters']['getSelectedDisplay']
  }

  static removeDisplayFromList(id) {
    return store.dispatch('removeDisplayFromList', id)
  }

  static setDisplays(result) {
    return store.dispatch('setDisplayList', result)
  }

  static setSelectedDisplay(display) {
    return store.dispatch('setSelectedDisplay', display)
  }

  static setSelectedDisplayByIndex(index) {
    return store.dispatch('setSelectedDisplayByIndex', index)
  }

  static updateDisplayInList(item) {
    return store.dispatch('updateDisplayList', item)
  }
}
