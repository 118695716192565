import {isInt} from '@/plugins/util'

export const changedFields = (old_values, new_values) => {
  let changed = {}
  for (let field in old_values) {
    if (!isNaN(old_values[field])) {
      if (isInt(old_values[field])) {
        if (parseInt(new_values[field], 10) !== parseInt(old_values[field], 10)) {
          changed[field] = parseInt(new_values[field], 10)
        }
      } else if (new_values[field] !== old_values[field]) {
        changed[field] = new_values[field]
      }
    } else if (new_values[field] !== old_values[field]) {
      changed[field] = new_values[field]
    }
  }
  return changed
}

export const checkFieldsForNull = (obj) => {
  let response = {}
  for (let i in obj) {
    if (obj[i] === '') {
      response[i] = 'NULL'
    } else {
      response[i] = obj[i]
    }
  }
  return response
}

export const getDifferenceOfObjects = (o1, o2) => {
  let k, kDiff, diff = {}
  for (k in o1) {
    // eslint-disable-next-line no-empty
    if (!Object.prototype.hasOwnProperty.call(o1, k)) {
    } else if (typeof o1[k] !== 'object' || typeof o2[k] !== 'object') {
      if (!(k in o2) || o1[k] !== o2[k]) {
        diff[k] = o2[k]
      }
      // eslint-disable-next-line no-cond-assign
    } else if (kDiff = getDifferenceOfObjects(o1[k], o2[k])) {
      diff[k] = kDiff
    }
  }
  for (k in o2) {
    if (Object.prototype.hasOwnProperty.call(o2, k) && !(k in o1)) {
      diff[k] = o2[k]
    }
  }
  for (k in diff) {
    if (Object.prototype.hasOwnProperty.call(diff, k)) {
      return diff
    }
  }
  return false
}
