<template>
  <v-form v-model="valid" ref="addCompany">
    <modals-container v-on:add="saveCompany" v-on:reset="resetForm"></modals-container>
    <v-layout>
      <v-flex xs12>
        <v-toolbar color="#f8f9fa" dark>
          <sales-order-input v-on:setCompanyFormFromSalesOrderDetails="updateCompanyForm"
                             v-model="salesordernumber"></sales-order-input>
          <v-spacer></v-spacer>
          <v-btn class="success" @click="save">Save</v-btn>
        </v-toolbar>
        <v-card>
          <company-form :company="company"></company-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import CompanyForm from '@/components/forms/company-form'
import CompanyMixin from '@/mixins/company-mixin'
import SalesOrderInput from '@/components/inputs/salesorder-input'
import AddCompanyVerifyModal from '@/components/modals/AddCompanyVerify'
import CompanyModel from '@/store/models/CompanyModel'
import toast from '@/plugins/notification'

export default {
  name: 'add-company',
  data: () => ({
    salesordernumber: '',
    valid: false
  }),
  methods: {
    isValidForm(params) {
      return params.zip.length <= 20 & params.phone.length <= 100 && params.address2.length <= 100
          && params.address1.length <= 100 && params.businessname.length <= 100
    },
    resetForm() {
      this.$refs.addCompany.resetValidation()
      this.resetCompany()
    },
    save() {
      if (this.$refs.addCompany.validate() && this.isValidForm(this.company)) {
        this.$modal.show(AddCompanyVerifyModal, {item: this.company}, {
          height: 'auto',
          width: '500',
          pivotX: .50,
          pivotY: .33,
          clickToClose: false
        })
      }
    },
    saveCompany() {
      this.create()
    },
    updateCompanyForm() {
      this.valid = false
      let companyInformation = CompanyModel.getSelectedCompany()
      if (companyInformation.country.length > 3) {
        toast.displayError('The country for this Sales Order is invalid. Please fix this before proceeding.')
        return;
      }
      this.valid = true
      Object.assign(this.company, companyInformation)
    }
  },
  components: {
    CompanyForm,
    SalesOrderInput
  },
  mixins: [CompanyMixin]
}
</script>
