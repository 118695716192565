<template>
  <v-container
      fluid
  >
    <v-row no-gutters
           align="center"
           justify="center"
           class="mb-2"
    >
      <v-col
          cols="12"
          sm="8"
          md="4"
      >
        <v-img :src="banner" height="256" contain></v-img>
      </v-col>
    </v-row>
    <template v-if="login_available">
      <v-row no-gutters
             align="center"
             justify="center"
      >
        <v-col
            cols="12"
            sm="6"
            md="3"
        >
          <v-card class="elevation-12">
            <v-toolbar
                color="primary"
                dark
                flat
            >
              <v-toolbar-title>Welcome</v-toolbar-title>
              <v-spacer/>
            </v-toolbar>
            <v-card-text>
              <v-form
                  ref="form"
                  v-model="valid"
                  :lazy-validation="lazy"
              >
                <v-text-field
                    label="User Name"
                    v-model="name"
                    :rules="nameRules"
                    prepend-icon="mdi-account"
                    type="text"
                    v-on:keyup.enter="validate"
                />
                <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    label="Password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-on:keyup.enter="validate"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn @click="validate" color="primary" block>Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar
          v-model="snackbar"
          color="error"
          bottom
          vertical
      >
        There was an error in retrieving your account information. Please try signing in again.
        <v-btn
            dark
            text
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
    </template>
    <template v-else>
      <v-row no-gutters align="center"
             justify="center">
        <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
        ></v-progress-circular>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import SiteModel from '@/store/models/SiteModel'
import UserModel from '@/store/models/UserModel'
import toast from '@/plugins/notification'
import {getBrowser, getEncryptedPassword} from '@/plugins/util'
import {getOffset} from '@/plugins/datetime'

export default {
  name: 'login',
  data: () => ({
    banner: '',
    lazy: false,
    login_available: false,
    name: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 100) || 'Name must be less than 100 characters'
    ],
    password: '',
    passwordRules: [
      v => !!v || 'Password is required',
      v => (v && v.length <= 100) || 'Password must be less than 100 characters'
    ],
    snackbar: false,
    valid: true
  }),
  mounted() {
    this.getStatus()
  },
  methods: {
    getStatus() {
      SiteModel.getSiteStatus()
          .then(result => {
            if (result.status.status === 'operational') {
              this.banner = result.image.filelocation + result.image.filename
              this.login_available = true
            }
          })
          .catch(error => {
            toast.displayError('There was an error retrieving the data. Please try again later.')
            console.error(error)
          })
    },
    isValidRoute(e) {
      return (Object.prototype.hasOwnProperty.call(e, "route") && e.route.constructor === Array && e.route !== null
          && e.route.length > 0)
    },
    login() {
      this.snackbar = false
      let browser = getBrowser()
      UserModel.login({
        email: this.name.trim(),
        password: getEncryptedPassword(this.password),
        sessionkey: 'login',
        browser: browser.name,
        browserversion: browser.version,
        platform: browser.os,
        browserlocaltime: new Date().toString(),
        localoffset: getOffset()
      })
          .then(result => {
            if (this.isValidRoute(result)) {
              this.$router.push('/page')
            } else {
              this.snackbar = true
            }
          })
          .catch(error => {
            this.snackbar = true
          })
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.login()
      }
    }
  }
}
</script>
